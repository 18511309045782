<script setup lang="ts">
    const nuxtApp = useNuxtApp();
    const route = useRoute();
    const cartStore = useCartStore();
    const { totalItems, cartStatus } = storeToRefs(cartStore);
    const { openCartDrawer } = useCart();

    const cartReady = computed(() => cartStatus.value === 'ready');
    const isCartPage = computed(() => route.path.includes('/cart'));

    const handleOpenCartDrawer = () => {
        openCartDrawer();
        nuxtApp.$gtm.viewCart(cartStore.$state.checkout);
    };
</script>
<template>
    <span :class="['atm-cart-button relative', { 'opacity-60': !cartReady }]">
        <span
            v-if="totalItems > 0"
            class="absolute right-[-30%] top-[-30%] flex h-4 w-4 items-center justify-center rounded-full bg-woom-red text-[11px] leading-4 text-woom-white">
            <span class="font-mono">{{ totalItems }}</span>
        </span>
        <!-- TODO: missing a11y translation for cart  -->
        <nuxt-link
            v-if="isCartPage"
            to="#main"
            aria-current="page"
            aria-label="Cart"
            :class="['transition-colors', { 'hover:text-woom-red': cartReady && !isCartPage, 'pointer-events-none text-woom-red': isCartPage }]">
            <woom-icon-cart class="h-6 w-6 fill-current" />
        </nuxt-link>
        <button
            v-else
            :aria-label="$t('cta.cart.openDrawer')"
            :class="['transition-colors', { 'hover:text-woom-red': cartReady && !isCartPage }]"
            :disabled="!cartReady"
            @click="handleOpenCartDrawer">
            <woom-icon-cart class="h-6 w-6 fill-current" />
        </button>
    </span>
</template>
