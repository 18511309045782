<script setup lang="ts">
    declare interface HeaderMobileNavBar {
        menuLevel: number;
        menuLevelTitle: string;
    }

    defineProps<HeaderMobileNavBar>();

    const emits = defineEmits(['toggle', 'close', 'back']);

    const isOpen = ref<boolean>();

    const appStore = useAppStore();

    const toggle = () => {
        if (appStore.menuOffset) isOpen.value = !isOpen.value;
        emits('toggle');
    };
    const close = () => {
        emits('close');
        isOpen.value = false;
    };
    useOnNavigation(close);

    const back = (level: number) => {
        emits('back', level);
    };
</script>
<template>
    <nav
        :class="[
            'mol-header-mobile-nav-bar top-0 z-xl flex h-14 items-center justify-between bg-white px-6 drop-shadow lg:hidden',
            { 'fixed w-full': isOpen },
            { sticky: !isOpen },
        ]">
        <button
            v-if="menuLevel <= 1"
            class="transition-colors hover:text-woom-red focus:text-woom-red"
            @click="toggle()"
            :aria-label="menuLevel === 0 ? $t('cta.menu.openMobile') : $t('cta.menu.closeMobile')">
            <woom-icon-menu
                v-if="menuLevel === 0"
                class="h-6 w-6 fill-current" />
            <woom-icon-close
                v-else
                class="h-6 w-6 fill-current" />
        </button>
        <button
            v-else
            class="transition-colors hover:text-woom-red focus:text-woom-red"
            @click="back(menuLevel)"
            :aria-label="$t('cta.menu.back')">
            <woom-icon-chevron-left class="h-6 w-6 fill-current" />
        </button>

        <span v-if="menuLevelTitle">
            <renderer-html :content="woomTextFormat(menuLevelTitle)" />
        </span>
        <nuxt-link
            v-else
            class="inline-flex items-center"
            :to="$helpers.generateLocalePath('/')"
            :aria-label="$t('error.homepage')">
            <woom-icon-woom-logo class="h-5 fill-woom-red" />
        </nuxt-link>
        <div
            v-if="menuLevel <= 1"
            class="flex gap-4">
            <atm-account-button />
            <atm-cart-button />
        </div>
        <button
            v-else
            class="transition-colors hover:text-woom-red focus:text-woom-red"
            @click="close()"
            :aria-label="$t('cta.menu.closeMobile')">
            <woom-icon-close class="h-6 w-6 fill-current" />
        </button>
    </nav>
</template>
