const friendBuyUSId = '5f29080c-6464-44eb-a969-2e16142b5e57';

export const useFriendBuyUS = () => {
    const { isUS } = useLocales();
    if (!isUS.value || !import.meta.browser) return;

    window.friendbuyAPI = window.friendbuyAPI || [];

    // registers your merchant using your merchant ID found in the
    // retailer app https://retailer.friendbuy.io/settings/general
    window.friendbuyAPI.merchantId = friendBuyUSId;
    window.friendbuyAPI.push(['merchant', window.friendbuyAPI.merchantId]);

    useHead({
        script: [
            {
                src: 'https://static.fbot.me/friendbuy.js',
                async: true,
                key: 'friendbuy',
            },
            {
                src: `https://campaign.fbot.me/${friendBuyUSId}/campaigns.js`,
                async: true,
                key: 'friendbuy-campaigns',
            },
        ],
    });
};
