<script setup lang="ts">
    interface DealerButtonProps {
        showIcon?: boolean;
    }
    defineProps<DealerButtonProps>();

    const { iso2Country } = useLocales();
    const dealerUrl = ref(
        iso2Country.value.toLowerCase() === 'us'
            ? 'https://us-checkout.woom.com/apps/store-locator'
            : 'https://intl-checkout.woom.com/apps/dealerlocator',
    );
</script>
<template>
    <nuxt-link
        :to="dealerUrl"
        target="_blank"
        class="gtm-click-dealer-locator atm-dealer-button flex items-center gap-3 border-b border-woom-grey-mid py-3 text-base transition-colors hover:text-woom-red">
        <woom-icon-search
            v-if="showIcon"
            class="h-6 w-6 fill-current" />
        <span :class="{ 'text-woom-grey-moon': showIcon }">{{ $t('dealerLocator') }}</span>
    </nuxt-link>
</template>
