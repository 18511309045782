<script setup lang="ts">
    import type { MenuItem as WoomMenuItem } from '~/graphql/generated';

    export interface DestopMenuItemProps {
        handleMenuKeypress: ({ isOpen, event }: { isOpen: boolean; event: KeyboardEvent }) => void;
        item: WoomMenuItem;
        to?: string;
        menuLevel?: number | null;
    }
    const props = defineProps<DestopMenuItemProps>();
    const emit = defineEmits<{
        (e: 'details', data: WoomMenuItem): void;
    }>();

    const isOpen = ref(false);

    const itemId = computed(() => {
        if (!props.item) return;
        return textToId(props.item.title);
    });

    const handleFocus = () => {
        isOpen.value = false;
    };
    const handleInteraction = (event: KeyboardEvent) => {
        if (event instanceof KeyboardEvent) {
            const key = event.code;
            if (['Enter', 'NumpadEnter', 'Space'].includes(key)) isOpen.value = !isOpen.value; // only space & enter
            props.handleMenuKeypress({
                isOpen: isOpen.value,
                event,
            });
            return;
        }
    };
    const handleBikeDetail = () => {
        emit('details', props.item);
    };
</script>
<template>
    <button
        v-if="item.items && (item?.items.length ?? 0)"
        :aria-expanded="isOpen"
        :aria-controls="`${itemId}-submenu`"
        :id="itemId"
        aria-haspopup="true"
        class="transition-colors hover:text-woom-red focus:text-woom-red"
        type="button"
        @focus="handleFocus"
        @keydown.esc.tab.space.enter="handleInteraction($event)">
        <renderer-html :content="woomTextFormat(item.title)" />
    </button>
    <nuxt-link
        v-else
        :to="to"
        class="gtm-click-navigation transition-colors hover:text-woom-red focus:text-woom-red"
        :data-menu-id="itemId"
        :data-menu-level="menuLevel"
        @focus="handleBikeDetail"
        @mouseover="handleBikeDetail"
        @keydown.esc.tab="handleMenuKeypress({ event: $event, isOpen: false })">
        <renderer-html :content="woomTextFormat(item?.title)" />
    </nuxt-link>
</template>
