// https://pinia.vuejs.org/core-concepts/#setup-stores
export const useNotificationStore = defineStore('woom-store-notification', () => {
    const sessionStorageKey = 'closedNotificationIds';
    const closedNotificationIds = ref(new Set<string>());
    const addClosedNotificationId = (id: string) => {
        closedNotificationIds.value.add(id);
        sessionStorage.setItem(sessionStorageKey, JSON.stringify([...closedNotificationIds.value]));
    };
    const initClient = async () => {
        await nextTick();
        const storedIdsString = sessionStorage.getItem(sessionStorageKey) ?? '[]';
        const storedIds: string[] = JSON.parse(storedIdsString);
        storedIds.forEach((id) => addClosedNotificationId(id));
    };
    if (import.meta.browser) {
        initClient();
    }
    return { closedNotificationIds, addClosedNotificationId };
});

export type NotificationStoreType = ReturnType<typeof useNotificationStore>;

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useNotificationStore, import.meta.hot));
}
