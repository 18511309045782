<script setup lang="ts">
    const appStore = useAppStore();
    const authStore = useAuthStore();
    const cartStore = useCartStore();
    const nuxtApp = useNuxtApp();
    const route = useRoute();
    const notificationStore = useNotificationStore();
    const { locale, language, country, isoLocale, isUS } = useLocales();

    await appStore.checkWebsite({ locale, language, country });

    const { notifications } = storeToRefs(appStore);
    let { closedNotificationIds } = storeToRefs(notificationStore);

    const notificationsWithVisibility = computed(() =>
        notifications.value.map((notification) => {
            const isVisible = computed(
                () =>
                    !(
                        (notification.lang !== 'en_US' && isUS.value) || // US only filtering
                        notification.data.path_excludes?.includes(route.path) || // route filter
                        // notification by ID exclude
                        closedNotificationIds.value.has(notification.id)
                    ),
            );
            return {
                ...notification,
                isVisible,
            };
        }),
    );
    const notificationIdToShow = computed(() => notificationsWithVisibility.value.find((notification) => notification.isVisible.value)?.id);

    useFriendBuyUS();

    onMounted(() => {
        cartStore.fetch({ locale: locale.value, isoLocale: isoLocale.value, $gtm: nuxtApp.$gtm, authStore });
    });
</script>

<template>
    <div class="absolute left-0 top-0 z-xl flex">
        <a
            class="relative inline-flex h-0 items-center justify-center overflow-hidden rounded-md bg-woom-red px-4 text-base font-bold text-woom-white transition-all focus:h-12"
            href="#main-content">
            <span>{{ $t('cta.skipLink') }}</span>
        </a>
    </div>
    <client-only>
        <lazy-org-drawer-group />
        <!-- TODO: can we have one modal that rules them all here as well? -->
        <lazy-mol-video-modal />
        <lazy-org-global-dialog />
        <lazy-mol-account-dialog />
    </client-only>
    <template
        v-for="notification in notificationsWithVisibility"
        :key="notification.id">
        <lazy-mol-notification
            v-show="notificationIdToShow === notification.id"
            :notification="notification" />
    </template>
    <org-header />

    <main
        id="main-content"
        class="min-h-screen-mobile lg:min-h-screen-desktop">
        <nuxt-page />
    </main>
    <lazy-mol-footer />
    <mol-toast-container />
</template>
