<script setup lang="ts">
    interface AdditionalLinkButtonProps {
        theme?: 'hamburger';
    }
    defineProps<AdditionalLinkButtonProps>();

    const appStore = useAppStore();
    const { website } = storeToRefs(appStore);
    const { generateStringOnlyURL } = useURL();

    const additionalLinkUrl = computed(() => {
        if (!website.value?.shop?.additional_link) return;
        return generateStringOnlyURL(website.value?.shop?.additional_link);
    });

    const icon = computed(() => website.value?.shop?.additional_link_icon?.data || 'woom-icon-help-bubble');
    const label = computed(() => website.value?.shop?.additional_link?.label);
</script>

<template>
    <nuxt-link
        v-if="label && additionalLinkUrl"
        :to="additionalLinkUrl"
        :class="[
            'atm-contact-button text-base transition-colors hover:text-woom-red',
            {
                'flex items-center gap-3 border-b border-woom-grey-mid py-3 ': theme === 'hamburger',
            },
        ]">
        <template v-if="theme === 'hamburger'">
            <component
                :is="icon.icon"
                :type="icon.type"
                class="h-6 w-6 fill-current" />
        </template>
        <span :class="{ 'text-woom-grey-moon': theme === 'hamburger' }">{{ label }}</span>
    </nuxt-link>
</template>
